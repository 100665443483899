<template>
  <div class="addAccount">
    <div class="a-title">
      {{ channelInfo.name }} —
      {{ channelInfo.type == 'add' ? '添加' : '编辑' }}账号
    </div>
    <van-form @submit="submit">
      <van-cell-group inset>
        <van-field
          name="radio"
          label="账号状态"
          v-if="channelInfo.type != 'add'"
        >
          <template #input>
            <van-radio-group v-model="form.status" direction="horizontal">
              <van-radio :name="10" checked-color="#fe8154">开启</van-radio>
              <van-radio :name="20" checked-color="#fe8154">关闭</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          label="账号昵称"
          v-model="form.platformName"
          placeholder="请输入账号昵称(必填)"
          :rules="[{ required: true, message: '请输入账号昵称' }]"
        />
        <van-field
          label="账号ID"
          v-model="form.platformCode"
          placeholder="请输入账号ID(必填)"
          :rules="[{ required: true, message: '请输入账号ID' }]"
        />
        <van-field
          v-model="form.platformTypeName"
          is-link
          readonly
          name="picker"
          label="账号分类"
          placeholder="请选择账号分类(必填)"
          :rules="[{ required: true, message: '请选择账号分类' }]"
          @click="showSelect = true"
        />
        <van-field
          label="粉丝量"
          type="number"
          v-model="form.fansNum"
          placeholder="请输入当前粉丝量(选填)"
        />
        <van-field
          autosize
          label="账号地址"
          v-model="form.userLink"
          type="textarea"
          placeholder="请输入个人账号地址(选填) 例:https://"
          :rules="[{ validator, message: '请输入正确的链接地址' }]"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block class="submitBtn" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <!-- 分类 -->
    <van-popup v-model:show="showSelect" position="bottom">
      <van-picker
        value-key="name"
        :columns="columns"
        @confirm="onConfirm"
        @cancel="showSelect = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
import {
  getPromotionType,
  addPromotion,
  getPromotionInfo,
  editPromotion
} from '@/api/information'
export default {
  name: 'addAccount',
  data() {
    return {
      form: {
        status: '',
        platformName: '',
        platformCode: '',
        platformType: '',
        platformTypeName: '',
        fansNum: '',
        userLink: ''
      },
      showSelect: false,
      columns: [],
      channelInfo: {}
    }
  },
  async mounted() {
    this.channelInfo = this.$route.query
    if (!this.channelInfo.promotionPlatformId) {
      return this.$router.replace('/information')
    }
    this.columns = await getPromotionType()
    if (this.channelInfo.type == 'edit') {
      let res = await getPromotionInfo({
        platformId: this.channelInfo.platformId
      })
      this.form = res
      let type = this.columns.find(item => {
        return item.id == res.platformType
      })
      this.form.platformTypeName = type ? type.name : ''
    }
  },
  methods: {
    validator(val) {
      let strRegexp = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/
      if (val != '') {
        if (!strRegexp.test(val)) {
          return false
        }
        if (this.channelInfo.linkType == '20') {
          return val.toLowerCase().includes(this.channelInfo.userInfoLink)
        }
      }
      return true
    },
    onConfirm(value) {
      this.form.platformType = value.id
      this.form.platformTypeName = value.name
      this.showSelect = false
    },
    async submit() {
      if (this.channelInfo.type == 'add') {
        await addPromotion(Object.assign(this.channelInfo, this.form))
      } else {
        await editPromotion(Object.assign(this.channelInfo, this.form))
      }
      Toast('提交成功')
      // this.$router.replace('/bindingChannel')
      this.$router.back(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.addAccount {
  padding: 20px 0px;
  height: 100vh;
}
.a-title {
  margin: 0px 30px 26px;
  font-size: 32px;
  padding: 20px 20px 20px;
  border-radius: 14px;
  color: #fd846f;
  background: white;
}
.submitBtn {
  background-image: linear-gradient(
    to right,
    #ff9f71,
    #ff9567,
    #ff8b5d,
    #fe8154,
    #fe764b
  );
  color: white;
  font-size: 30px;
}
</style>
